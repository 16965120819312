import { SelectProps } from "@cloudscape-design/components";
import { Auth } from "aws-amplify";
import { naturalSortNodeId } from "components/location-hierarchy/hierarchy/utils";
import { useMemo } from "react";
import { AlertRule, LocationNode, LocationType, StateAlertConfigCode } from "types/custom";

export const selectLocationProps = (data: any, type: LocationType): SelectProps.Option => {
    const code = data[type + 'no'] || data[type + 'code']
    return {
        label: String(data[type] || data[type + 'name'] || data[type + 'id']),
        value: String(data[type + 'id']),
        labelTag: code ? `${code} [${data[type + 'id']}]` : `${data[type + 'id']}`,
        description: `Created at ${new Date(data.datecreated).toLocaleString().replace(',', '')}${data.datemodified ? '. Modified at ' + new Date(data.datemodified).toLocaleString().replace(',', '') : ''}`,
    };
};

export const useLocationOptions = <T = any>(data: { child: T[] }, type: LocationType) => useMemo(() => data?.child?.map((data: T) => selectLocationProps(data, type)) || [], [data, type]);

export const addRecentlyUsed = (data: any[], stored: LocationNode[], selectUpTo: (string | undefined)[] = [], isLoading: boolean = false) => {
    let parsed = stored || [];
    selectUpTo = selectUpTo.filter(x => x !== undefined)
    while (selectUpTo.length > 0) {
        const selectedId = selectUpTo.shift()?.toString()
        parsed = parsed.find(loc => loc.id === selectedId)?.children || [];
    }
    const locationOptions = parsed.sort(naturalSortNodeId).map((loc) => ({
        label: `${loc.name}`,
        value: `${loc.id}`,
        labelTag: loc.code ? `${loc.code} [${loc.id}]` : `${loc.id}`,
    }));
    return [{
        label: 'Recently Used',
        options: locationOptions,
    }, {
        label: 'All Locations',
        options: !isLoading ? data : [],
    }];
}

export const getMatchesCountText = (count: number | undefined) => {
    return `${count} ${count === 1 ? 'match' : 'matches'}`;
};

export const convertUTCHourToLocal = (value: number, optional: boolean = false) => {
    if (optional && !value) return 0;
    const getMillisecondsForHour = new Date().setUTCHours(+value);
    return new Date(getMillisecondsForHour).getHours();
};

export const convertLocalHourToUTC = (value: string, optional: boolean = false) => {
    if (optional && !value) return 0;
    const getMillisecondsForHour = new Date().setHours(+value);
    return new Date(getMillisecondsForHour).getUTCHours();
};

export const getAuthConfig = async () => {
    const token = (await Auth.currentSession()).getIdToken().getJwtToken();
    const config = {
        headers: {
            'Authorization': `Bearer ${token}`
        }
    };
    return config;
};

export function toTitleCase(str: string) {
    return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
};

export function thresholdCodeToName(code?: StateAlertConfigCode) {
    switch (code) {
        case StateAlertConfigCode.LL:
            return 'Low-Low';
        case StateAlertConfigCode.L:
            return 'Low';
        case StateAlertConfigCode.H:
            return 'High';
        case StateAlertConfigCode.HH:
            return 'High-High';
        default:
            return ''
    }
}

// Polyfill and replacement for Object.groupBy
export function groupBy<T, K extends keyof any>(arr: T[], key: (i: T) => K) {
    return arr.reduce((groups, item) => {
        (groups[key(item)] ||= []).push(item);
        return groups;
    }, {} as Record<K, T[]>);
}

export function getThresholdsOverlaps(allRules: AlertRule[]) {
    return Object.entries(groupBy(allRules, (rule) => rule.measure))
        .map(([measure, rules]) => {
            const rulesForLower = rules?.filter((rule) => rule.threshold_code === StateAlertConfigCode.L || rule.threshold_code === StateAlertConfigCode.LL);
            const maxLowValue = rulesForLower?.length ? rulesForLower.reduce((prev, current) =>
                (prev && prev.threshold_value > current.threshold_value) ? prev : current
            ).threshold_value : null;

            const rulesForHigher = rules?.filter((rule) => rule.threshold_code === StateAlertConfigCode.H || rule.threshold_code === StateAlertConfigCode.HH);
            const minHighValue = rulesForHigher?.length ? rulesForHigher.reduce((prev, current) =>
                (prev && prev.threshold_value < current.threshold_value) ? prev : current
            ).threshold_value : null;

            return maxLowValue && minHighValue && maxLowValue >= minHighValue ? measure : '';
        })
        .filter(Boolean);
}
//API url
export const API_URL_PATH_UM_ROLES = '/role';
export const API_URL_PATH_UM_MY_ROLES = '/role/myrole';
export const API_URL_UM_RESOURCE_REGIONS = 'locations';
export const API_URL_UM_RESOURCE_COUNTRIES = 'locations/region';
export const API_URL_UM_RESOURCE_SITES = 'locations/country';
export const API_URL_UM_RESOURCE_BUILDINGS = '/locations/site';
export const API_URL_UM_RESOURCE_FLOORS = '/locations/building';
export const API_URL_UM_RESOURCE_ROOMS = '/locations/floor';

export const API_URL_PATH_DM = '/deviceRegistry';
export const API_URL_PATH_DM_DEVICE_LIST = '/deviceRegistry/device/list';
export const API_URL_PATH_DM_SYNC_DEVICES = '/deviceRegistry/syncDevices';
export const API_URL_PATH_LOCATIONS_HIERARCHY = '/deviceRegistry/device/locations';
export const API_URL_PATH_DM_DEVICE = '/deviceRegistry/device';
export const API_URL_PATH_DEVICE_TYPES = '/deviceRegistry/deviceTypes';
export const API_URL_PATH_PHYSICAL_DEVICE_TYPES = '/deviceRegistry/physicalDeviceTypes';
export const API_URL_PATH_DM_RULE = '/deviceRegistry/rule';

export const API_URL_PATH_HIERARCHY = '/hierarchy';

export const API_URL_PATH_TAG = '/tag';
export const API_URL_PATH_TAG_BY_HIERARCHY = '/tag/byHierarchy';
export const API_URL_PATH_TAG_BULK = '/tag/bulk';

export const API_URL_PATH_IM_CONTACTS = '/incidentMgt/contact';
export const API_URL_PATH_IM_GROUPS = '/incidentMgt/notification';
export const API_URL_PATH_IM_PLANS = '/incidentMgt/escalation';
export const API_URL_PATH_IM_INCIDENTS = '/incidentMgt/incident';

export const API_URL_PATH_GROUP_AUTOCOMPLETE = 'group/autocompletion';
export const API_URL_PATH_GROUP_LIST = 'group';

//App url paths
//Login
export const URL_PATH_LOGIN = 'login';

//Dashboard
export const URL_PATH_DASHBOARD = '/dashboard';

//User manager
export const URL_PATH_USER_MANAGER = '/user-manager';
export const URL_PATH_USER_MANAGER_ROLES = 'roles';
export const URL_PATH_USER_MANAGER_ROLES_CREATE = 'roles/create';
export const URL_FULL_PATH_USER_MANAGER_ROLES = '/user-manager/roles';

//Device manager
export const URL_PATH_DEVICE_MANAGER = '/device-manager';
export const URL_PATH_DEVICE_MANAGER_LIST = 'list';
export const URL_FULL_PATH_DEVICE_MANAGER_LIST = '/device-manager/list';
export const URL_PATH_DEVICE_MANAGER_INSTALL_DEVICE = '/device-manager/install';

//Notification manager
export const URL_FULL_PATH_NOTIFICATION_MANAGER = '/notification-manager';
export const URL_FULL_PATH_NOTIFICATION_MANAGER_VIEW = '/notification-manager/view';

//Device Group Manager
export const URL_FULL_PATH_DEVICE_GROUP_MANAGER = '/device-group-manager';
export const URL_FULL_PATH_DEVICE_GROUP_MANAGER_LIST =
    '/device-group-manager/list';
export const URL_FULL_PATH_DEVICE_GROUP_MANAGER_CREATE =
    '/device-group-manager/create';

//Incident Manager
export const URL_FULL_PATH_INCIDENT_MANAGER = '/incident-manager';

//Unauthorized
export const URL_PATH_UNAUTHORIZED = 'unauthorized';

//Activate contact
export const URL_PATH_ACTIVATE_CONTACT = '/activate-contact';

import { Asset, DeviceState } from "types/custom";

export const buttonDropdownItems = (selectedDevices: Asset[]) => {
    const isEnabledFor = (enabledStates: DeviceState[], allowsBulk: boolean = false) =>
        selectedDevices.length > 0 && selectedDevices.every(device => enabledStates.includes(device.deviceState)) && (allowsBulk || selectedDevices.length === 1);

    return [
        {
            text: 'Install',
            id: 'install',
            disabled: !isEnabledFor([DeviceState.disabled, DeviceState.inService], true),
        },
        {
            text: 'Update',
            id: 'edit',
            disabled: !isEnabledFor([DeviceState.disabled, DeviceState.inService], false),
        },
        {
            text: 'Disable',
            id: 'disable',
            disabled: !isEnabledFor([DeviceState.inService], true),
        },
        {
            text: 'Delete',
            id: 'delete',
            disabled: !isEnabledFor([DeviceState.disabled, DeviceState.inService], false),
        },
    ];
};

